<template>
	<div>
		<div class="row text-left">
			<button class="btn btn-secondary ml-3" @click="close"> <font-awesome-icon :icon="['fal', 'times']"/> </button>
		</div>
		<div class="row">
			<div class="text-center m-auto">
				<h2>{{ horse.horse_nom }}</h2>

				<!-- J-3 J-2 J-1 -->

				<h4>{{ getDateLabel(task.task_date) }} {{ formatDate(task.task_date) }}</h4>
			</div>

			<div v-if="task && task.task_id">
				<button @click="goToDeleteTask" class="btn btn-secondary"><font-awesome-icon :icon="['fal', 'trash-alt']" /></button>
			</div>
		</div>

		<div class="mt-4">
			<label>{{ $t('task_planning.choix_operateur') }}</label>
			<div class="text-center">
				<b-avatar
					v-for="contact in planning.contact"
					role="button" 
					size="3em"
					:class="{ 'mr-2':true, 'active': contact.contact.contact_id == task.task_contact }"
					:style="'color: white;background-color:'+contact.contact.contact_color"
					:key="contact.contact.contact_id"
					@click.native="selectContact(contact.contact.contact_id)"
				>
					{{ contact.contact.contact_abbreviation }}
				</b-avatar>
			</div>
		</div>

		<div class="mt-4">
			<label>{{ $t('task_planning.choix_activite') }}</label>
	    	<e-select
				id="actes_types"
				v-model="task.actes_type"
	            track-by="actestype_id"
	            label="actestype_label"
				:placeholder="$t('task_planning.choix_activite')"
				:options="actes_types"
				:searchable="true"
				:group-select="false"
			>
				<template slot="option" slot-scope="{ option }">{{ checkIfTranslationExists('actes_type.'+option.actestype_code) ? $t('actes_type.'+option.actestype_code) : option.actestype_label }}</template>
				<template slot="singleLabel" slot-scope="{ option }">{{ checkIfTranslationExists('actes_type.'+option.actestype_code) ? $t('actes_type.'+option.actestype_code) : option.actestype_label }}</template>
				<template slot="noOptions">{{ $t('global.list_empty') }}</template>
			</e-select>
		</div>

		<ErrorAlert v-if="show_error_champ" messageI18n="error.TASK-C" class="mt-4"/>

        <LoadingSpinner v-if="processing"/>
		<div v-else class="mt-4 text-center">
			<div class="text-center mb-2 actionAddtask">
				<b-button variant="primary" class="mr-2" @click.prevent="saveForm"><font-awesome-icon :icon="['fal', 'save']" /> {{ $t("task_planning.save_and_close") }}</b-button>
	            <b-button variant="secondary" @click.prevent="saveAndNext"><font-awesome-icon :icon="['far', 'arrow-right']" /> {{ $t("task_planning.save_and_continue") }}</b-button>
			</div>

            <b-button pill variant="secondary" @click.prevent="nextHorse"><font-awesome-icon :icon="['far', 'times']" /> {{ $t("task_planning.passer_cheval") }}</b-button>
        </div>
	</div>
</template>

<style scoped>
	.b-avatar.active {
        border: 7px solid #ffffff4a;
	}
</style>

<script type="text/javascript">
	import TaskPlanning from "@/mixins/TaskPlanning.js"
    import Shutter from '@/mixins/Shutter.js'

	export default {
		name: 'WriteTaskPlanning',
		mixins: [TaskPlanning, Shutter],
		props: ['horse', 'date', 'task_edit', 'planning'],
		data () {
			return {
				task: {
					task_id: null,
					task_planning: null,
					task_horse: null,
					task_date: null,
					task_contact: null,
					task_actestype: null,
					actes_type: null
				},
				actes_types: [],
				next: false,
				processing: false,
				show_error_champ: false
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				const actes_type = this.planning.actes_type.filter(acte_type => acte_type.actes_type.active == null || acte_type.actes_type.active.actestypeactive_active)
				this.actes_types = actes_type.map(type => type.actes_type)

				this.task.task_planning = this.planning.taskplanning_id
				this.task.task_horse = this.horse.horse_id

				if(this.task_edit) {
					this.task.task_id = this.task_edit.task_id
					this.task.task_date = this.task_edit.task_date
					this.task.task_contact = this.task_edit.task_contact
					this.task.task_actestype = this.task_edit.task_actestype
					this.task.actes_type = this.actes_types.find(type => type.actestype_id == this.task_edit.task_actestype)
				}
				else {
					this.task.task_date = this.date
				}
			},

			selectContact(contact_id) {
				this.task.task_contact = contact_id
			},

			getDateLabel(date) {
				return this.getTrad("monte.jours."+new Date(date).getDay())
			},

			async saveForm() {
				this.processing = true
				this.show_error_champ = false

				if(!this.task.task_contact && !this.task.task_actestype) {
					this.show_error_champ = true
					this.processing = false
					return false
				}

				if(this.task.task_id) {
					await this.updateTask(this.task.task_id, this.task)
				}
				else {
					await this.createTask(this.task)
				}
				this.processing = false
                this.ok(this.next)
			},
			close(){
				this.ok(false)
			},
			async saveAndNext() {
				this.next = true
				await this.saveForm()
			},

			nextHorse() {
                this.ok(true)
			},

			checkIfTranslationExists(string) {
				return !(string === this.$t(string))
			},

			async goToDeleteTask() {
				await this.deleteTask(this.task.task_id)
				this.ok(false)
				this.successToast("toast.info_save_succes")
			}
		},

		watch: {
			'task.actes_type': {
				handler(val) {
					if(val) {
						this.task.task_actestype = val.actestype_id
					}
				},
				deep: true
			}
		},

		components: {
			LoadingSpinner: () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert: () => import('GroomyRoot/components/Alert/ErrorAlert'),
		}
	}

</script>