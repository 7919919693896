import Constants from '../../config.local.js'
import Common from '@/assets/js/common'

var TaskPlanning = {
    methods: {
        getAllTaskPlanning: async function(only_unarchived) {
            const url = Constants.TASK_PLANNING_URL +'?licence_key='+Constants.USER_LICENCE_KEY + '&only_unarchived='+only_unarchived
			const result = await this.$request.request_get_api("TaskPlanning::getAllTaskPlanning", url)
            .catch(error => {
                console.error("TaskPlanning::getAllTaskPlanning => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        getTaskPlanningGroupBy: function() {
            return [
                {
                    groupby_id: 1,
                    groupby_label:'Aucun'
                },
                {
                    groupby_id: 2,
                    groupby_label:'Age (Décroissant)'
                },
                {
                    groupby_id: 3,
                    groupby_label:'Age (Croissant)'
                },
                {
                    groupby_id: 4,
                    groupby_label:'Localisation'
                }
            ]
        },

        createTaskPlanning: async function(params) {
            const url = Constants.TASK_PLANNING_URL +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("TaskPlanning::createTaskPlanning", url, params, false)
        },

        updateTaskPlanning: async function(taskplanning_id, params) {
            const url = this.constructRoute(Constants.TASK_PLANNING_BY_ID_URL, {taskplanning_id}) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("TaskPlanning::updateTaskPlanning", url, params, false)
        },

        archiveTaskPlanning: async function(taskplanning_id, archive) {
            const url = this.constructRoute(Constants.TASK_PLANNING_ARCHIVE_BY_ID_URL, {taskplanning_id}) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("TaskPlanning::archiveTaskPlanning", url, { archive }, false)
        },

        getTaskPlanning: async function(taskplanning_id, start, end) {
            const url = this.constructRoute(Constants.TASK_PLANNING_BY_ID_URL, {taskplanning_id}) +'?licence_key='+Constants.USER_LICENCE_KEY+"&start="+start+"&end="+end
            const result = await this.$request.request_get_api("TaskPlanning::getTaskPlanning", url)
            .catch(error => {
                console.error("TaskPlanning::getTaskPlanning => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        },

        createTask: async function(params) {
            const url = Constants.TASK_URL +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("TaskPlanning::createTask", url, params, false)
        },

        updateTask: async function(task_id, params) {
            const url = this.constructRoute(Constants.TASK_BY_ID_URL, {task_id}) +'?licence_key='+Constants.USER_LICENCE_KEY
            return await this.$request.request_post_api("TaskPlanning::updateTask", url, params, false)
        },

        deleteTask: async function(task_id) {
            const url = this.constructRoute(Constants.TASK_BY_ID_URL, {task_id}) +'?licence_key='+Constants.USER_LICENCE_KEY
            const result = await this.$request.request_delete_api("TaskPlanning::deleteTask", url)
            .catch(error => {
                console.error("TaskPlanning::deleteTask => ERROR", error)
                return null
            })
            if(result.retour) return result.retour
            return null
        }
    }
}

export default TaskPlanning
